var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col justify-center items-center"},[_c('HeaderGlobal',{attrs:{"step":_vm.$t('global_step_01_03'),"title":_vm.$t('global_header_registration_method'),"titlemenu1":"","titlemenu2":"Manage Subscription"}}),_c('div',{staticClass:"w-11/12 mt-20 text-left font-proximaNormal"},[_c('div',{staticClass:"w-5/12 flex justify-between"},[_c('div',{staticClass:"w-full flex flex-col justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-sm"},[_vm._v(_vm._s(_vm.$t('global_subscription')))]),_c('SubscriptioIcon')],1),_c('div',{staticClass:"w-full flex flex-col justify-center items-center"},[_c('h3',{staticClass:"mb-6 text-sm"}),_c('PointIcon')],1),_c('div',{staticClass:"w-full ml-1 flex flex-col justify-end items-end"},[_c('h3',{staticClass:"mb-6 text-sm"},[_vm._v("Payment")]),_c('PaymentIcon')],1)]),_vm._m(0),_vm._m(1),_c('hr',{staticClass:"w-8/12 mb-8"}),_c('div',{staticClass:"w-5/12 flex justify-between font-proximaNormal"},[_c('div',{staticClass:"w-full flex flex-col justify-start items-start"},[_c('div',{staticClass:"flex justify-start items-start"},[_c('h3',{staticClass:"mb-6 mr-2 text-md"},[_vm._v("1 x")]),_c('UserIcon')],1)]),_vm._m(2),_vm._m(3)]),_c('hr',{staticClass:"w-8/12 mb-8"}),_vm._m(4),_c('h3',{staticClass:"mb-6 mt-20 text-lg font-proximaMedium"},[_vm._v("Upgrade")]),_c('div',{staticClass:"w-8/12 flex justify-between font-proximaNormal"},[_c('div',{staticClass:"w-full flex flex-col- justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-md text-blue"},[_vm._v(_vm._s(_vm.$t('join_us_profile_dog_breeder')))])]),_vm._m(5),_vm._m(6),_vm._m(7)]),_c('hr',{staticClass:"w-8/12 mb-8"}),_vm._m(8)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5 flex flex-col justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-lg"},[_vm._v("Current Plan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-5/12 flex justify-between font-proximaNormal"},[_c('div',{staticClass:"w-full flex flex-col justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-md text-blue"},[_vm._v("Pet Owner")])]),_c('div',{staticClass:"w-full flex flex-col justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-md"},[_vm._v("€3.99")])]),_c('div',{staticClass:"w-full flex flex-col justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-xs"},[_vm._v("/ month")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex flex-col justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-md"},[_vm._v("- €1.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex flex-col justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-xs"},[_vm._v("referral discount")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-5/12 flex justify-between font-proximaMedium"},[_c('div',{staticClass:"w-full flex flex-col justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-md text-blue"},[_vm._v("Monthly Total")])]),_c('div',{staticClass:"w-full flex flex-col justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-md"},[_vm._v("€2.99")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex flex-col justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-md"},[_vm._v("€5.99")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex flex-col justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-xs"},[_vm._v("/ month")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex flex-col justify-start items-start"},[_c('button',{staticClass:"buttonSelect"},[_vm._v("Select")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-8/12 flex justify-between font-proximaNormal"},[_c('div',{staticClass:"w-full flex flex-col- justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-md text-blue"})]),_c('div',{staticClass:"w-full flex flex-col- justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-md"},[_vm._v("€64.99")])]),_c('div',{staticClass:"w-full flex flex-col- justify-start items-start"},[_c('h3',{staticClass:"mb-6 text-xs"},[_vm._v("/ year")])]),_c('div',{staticClass:"w-full flex flex-col- justify-start items-start"},[_c('button',{staticClass:"buttonSelect"},[_vm._v("Select")])])])
}]

export { render, staticRenderFns }